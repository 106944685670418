import axios from '@/common/axios'
import store from '@/store'
import conf from '@/common/conf.js'
// 读取账号列表
const getAdminList = (form) => {
    let data = {}
    data.keyword = form.keyword
    data.pageNumber = form.pageNo
    data.pageSize = form.pageSize

    return axios({
        url: conf.apiUrl + '/member/index',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data
    })
}

// 下拉角色列表
const roleList = () => {
    let data = {}
    return axios({
        url: conf.apiUrl + '/member/roleList',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data
    })
}

// 添加账号
const adminAdd = (row) => {
    let data = {}
    data.username = row.user_name
    data.password = row.password
    data.nickname = row.nick_name
    data.phone = row.phone
    data.face = row.face
    data.role = row.roles
    data.status = row.status
    return axios({
        url: conf.apiUrl + '/member/add',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data
    })
}

// 编辑账号
const adminEdit = (row) => {
    let data = {}
    data.username = row.user_name
    data.password = row.password
    data.nickname = row.nick_name
    data.phone = row.phone
    data.face = row.face
    data.role = row.roles
    data.status = row.status
    data.uid = row.uid

    return axios({
        url: conf.apiUrl + '/member/edit',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data,
    })
}

// 删除账号
const adminDel = (row) => {
    let data = {}
    data.uid = row.uid
    return axios({
        url: conf.apiUrl + '/member/del',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data
    })
}

// 批量删除
const batchRemove = (row) => {
    let data = {}
    data.uid = row.uid
    return axios({
        url: conf.apiUrl + '/member/batchRemove',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data
    })
}

// 账号（修改密码）
const doAdminPassword = (row) => {
    let data = {}
    data.password = row.password
    data.source =row.source
    data.again_password = row.again_password
    return axios({
        url: conf.apiUrl + '/member/upPassword',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data: data
    })
}

// 列表员工状态滑块修改
const statusEdit = (row) => {
    return axios({
        url: conf.apiUrl + '/member/statusEdit',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data: row
    })
}

// 输出
export default {
    getAdminList,
    adminAdd,
    adminEdit,
    adminDel,
    doAdminPassword,
    statusEdit,
    roleList,
    batchRemove
}

<template>
  <div class="container-bottom">
    <!-- Copyright @ 2023 管理后台 -->
  </div>
</template>

<script>
export default {
  name: 'ContainerBottom',
}
</script>

<style scoped lang="scss">
.container-bottom {
  padding: 0 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  border-top: 1px dashed #dcdfe6;
}
</style>

import { createStore } from 'vuex'
import conf from '@/common/conf.js'

export default createStore({
  state: {
    // 登录token
    token: {token: '', expire: 0},
    // 角色
    role: '',
    // cdn地址
    cdn: '',
    // cdncode
    code: '',
    // 活动列表团队数据
    activityTeam: {
      dealers: {},
      group: {}
    }
  },
  getters: {
    // 返回token字符串（过期则返空字符串）
    doneToken(state) {
      let now = Date.parse(new Date()) / 1000
      
      if ((state.token.expire - now) < 30) {
        return ''
      } else {
        return state.token.token
      }
    },
    // 根据配置获取cdn
		getCdn() {
			return conf.cdn
		},
    // 根据配置获取cdn
		getCode() {
			return conf.code
		}
  },
  mutations: {
    // 设置token
    modifyToken(state, val) {
      state.token = val
    },
    // 设置角色
    modifyRole(state, val) {
      state.role = val
    },
    // 设置cdn地址
    modifyCdn(state, val) {
      state.cdn = val
    },
    modifyCode(state, val) {
      state.code = val
    },
    
    // 设置活动列表团队数据
    modifyActivityTeam(state, val) {
      switch(val.type) {
        case 'dealers':
          state.activityTeam.dealers = val.data
          break
        case 'group':
          state.activityTeam.group = val.data 
          break
        default:
          state.activityTeam = val
      }
    }
  },
  actions: {
  },
  modules: {
  }
})

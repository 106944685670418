// 文本
const AreaAgent = '区域代理'
const dealers = '经销商'
const group = '群主'

// 输出
export default {
    AreaAgent,
    dealers,
    group
}
import { createRouter, createWebHistory } from 'vue-router'
import HomeNav from '@/components/HomeNav.vue'
import store from '@/store'
import common from '@/common/common.js'
import lang from "@/common/language.js"
import login from '@/api/login.js'

export const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/LoginView.vue'),
    meta: {
      hiddenMenu: true,
      noLogin: true
    }
  },
  {
    path: '/',
    component: HomeNav,
    redirect: '/index', // 默认重定向到 /index
    meta: {
      hiddenMenu: false,
      noLogin: false
    },
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/index/IndexView2.vue'),
        meta: {
          title: '今日数据',
          icon: 'icon-chart-histogram',
          guard: ['Admin', 'Super', 'Dealers', 'Finance']
        },
      },
      {
        path: '/statistic',
        name: 'statistic',
        meta: {
          title: '历史数据',
          icon: 'icon-trend-two',
          guard: ['Admin', 'Super', 'Dealers']
        },
        children: [
          {
            path: 'dailyData',
            name: 'dailyData',
            component: () => import('@/views/statistic/DailyData.vue'),
            meta: {
              title: '每日统计',
              icon: 'icon-chart-pie-one',
              guard: ['Admin', 'Super']
            }
          },
          {
            path: 'platformData',
            name: 'platformData',
            component: () => import('@/views/statistic/PlatformData.vue'),
            meta: {
              title: `${lang.dealers}每日统计`,
              icon: 'icon-date-comes-back',
              guard: ['Admin', 'Super']
            }
          },
          {
            path: 'dealersData',
            name: 'dealersData',
            component: () => import('@/views/statistic/DealersData.vue'),
            meta: {
              title: `${lang.dealers}每日统计`,
              icon: 'icon-internal-data',
              guard: ['Dealers']
            }
          }
        ]
      },
      {
        path: '/manage',
        name: 'manage',
        meta: {
          title: '系统用户',
          icon: 'icon-peoples',
          guard: ['Admin', 'Super']
        },
        children: [
          {
            path: 'admin',
            name: 'AdminView',
            component: () => import('@/views/account/AdminView.vue'),
            meta: {
              title: '员工管理',
              icon: 'icon-user',
              guard: ['Admin', 'Super'],
              add: ['Admin', 'Super'],
              edit: ['Admin', 'Super'],
              status: ['Admin', 'Super'],
              del: ['Admin', 'Super']
            }
          },
          {
            path: 'dealersGroup',
            name: 'dealersGroup',
            component: () => import('@/views/manage/DealersGroup.vue'),
            meta: {
              title: `${lang.dealers}分组`,
              icon: 'icon-schedule',
              guard: ['Admin', 'Super'],
              add: ['Admin', 'Super'],
              edit: ['Admin', 'Super'],
              del: ['Admin', 'Super']
            }
          }
        ]
      },
      {
        path: '/question',
        name: 'question',
        meta: {
          title: '题库管理',
          icon: 'icon-notebook-one',
          guard: ['Admin', 'Super']
        },
        children: [
          {
            path: 'questionList',
            name: 'questionList',
            component: () => import('@/views/question/QuestionList.vue'),
            meta: {
              title: '问题列表',
              icon: 'icon-notebook-and-pen',
              guard: ['Admin', 'Super'],
              add: ['Admin', 'Super'],
              edit: ['Admin', 'Super'],
              del: ['Admin', 'Super']
            }
          },
          {
            path: 'questionBank',
            name: 'questionBank',
            component: () => import('@/views/question/QuestionBank.vue'),
            meta: {
              title: '题库列表',
              icon: 'icon-book-one',
              guard: ['Admin', 'Super'],
              list: ['Admin', 'Super'],
              add: ['Admin', 'Super'],
              edit: ['Admin', 'Super'],
              del: ['Admin', 'Super']
            }
          }
        ]
      },
      {
        path: '/material',
        name: 'material',
        meta: {
          title: '视频库',
          icon: 'icon-material',
          guard: ['Admin', 'Super']
        },
        children: [
          {
            path: 'videoCategory',
            name: 'videoCategory',
            component: () => import('@/views/material/VideoCategory.vue'),
            meta: {
              title: '视频分类',
              icon: 'icon-movie',
              guard: ['Admin', 'Super'],
              add: ['Admin', 'Super'],
              del: ['Admin', 'Super'],
              edit: ['Admin', 'Super']
            }
          },
          {
            path: 'videoList',
            name: 'videoList',
            component: () => import('@/views/material/VideoList.vue'),
            meta: {
              title: '视频管理',
              icon: 'icon-video',
              guard: ['Admin', 'Super'],
              add: ['Admin', 'Super'],
              del: ['Admin', 'Super'],
              edit: ['Admin', 'Super']
            }
          }
        ]
      },
      {
        path: '/activity',
        name: 'activity',
        meta: {
          title: '直播管理',
          icon: 'icon-video-two',
          guard: ['Admin', 'Super', 'Dealers']
        },
        children: [
          {
            path: 'activityList',
            name: 'activityList',
            component: () => import('@/views/activity/ActivityList.vue'),
            meta: {
              title: '直播列表',
              icon: 'icon-play-two',
              guard: ['Admin', 'Super', 'Dealers'],
              add: ['Admin', 'Super'],
              edit: ['Admin', 'Super'],
              del: ['Admin', 'Super'],
              reissue: ['Admin', 'Super'],
              copy: ['Admin', 'Super'],
              blackHome:['Admin'],
              upDown:['Admin'],
            }
          },
          {
            path: 'activityCategory',
            name: 'activityCategory',
            component: () => import('@/views/activity/ActivityCategory.vue'),
            meta: {
              title: '直播分类',
              icon: 'icon-category-management',
              guard: ['Admin', 'Super'],
              add: ['Admin', 'Super'],
              edit: ['Admin', 'Super'],
              del: ['Admin', 'Super']
            }
          }
        ]
      },
      {
        path: '/dealerManagement',
        name: 'dealerManagement',
        meta: {
          title: '经销商管理',
          icon: 'icon-sales-report',
          guard: ['Admin', 'Super']
        },
        children: [
          {
            path: 'dealersList',
            name: 'DealersList',
            component: () => import('@/views/dealerManagement/DealersList.vue'),
            meta: {
              title: `${lang.dealers}列表`,
              icon: 'icon-classroom',
              guard: ['Admin', 'Super'],
              recharge: ['Admin', 'Super'],
              refund: ['Admin', 'Super'],
              edit: ['Admin', 'Super'],
              kk: ['Admin'],
              del: ['Admin']
            }
          },
        ]
      },
      {
        path: '/groupLeader',
        name: 'groupLeader',
        meta: {
          title: '群管理员管理',
          icon: 'icon-every-user',
          guard: ['Admin', 'Super', 'Dealers']
        },
        children: [
          {
            path: 'group',
            name: 'Group',
            component: () => import('@/views/groupLeader/Group.vue'),
            meta: {
              title: `群管理员列表`,
              icon: 'icon-user-business',
              guard: ['Admin', 'Super', 'Dealers'],
              change: ['Admin', 'Super', 'Dealers'],
              edit: ['Admin', 'Super', 'Dealers'],
              pledit: ['Admin', 'Super'],
              export: ['Admin', 'Super'],
              migrate: ['Admin', 'Super']
            }
          }
        ]
      },
      {
        path: '/member',
        name: 'member',
        meta: {
          title: '会员管理',
          icon: 'icon-wechat',
          guard: ['Admin', 'Super', 'Dealers']
        },
        children: [
          {
            path: 'memberList',
            name: 'memberList',
            component: () => import('@/views/member/MemberList.vue'),
            meta: {
              title: '会员列表',
              icon: 'icon-people-plus',
              guard: ['Admin', 'Super', 'Dealers'],
              reissue: ['Admin', 'Super'],
              group: ['Admin', 'Super', 'Dealers'],
              del: ['Admin', 'Super','Dealers'],
              edit: ['Admin', 'Super', 'Dealers']
            }
          }
        ]
      },
      {
        path: '/consumptionRecords',
        name: 'consumptionRecords',
        meta: {
          title: '资源消耗记录',
          icon: 'icon-chart-histogram',
          guard: ['Admin', 'Super', 'Dealers']
        },
        children: [
          {
            path: 'consumeLog',
            name: 'ConsumeLog',
            component: () => import('@/views/consumptionRecords/ConsumeLog.vue'),
            meta: {
              title: '资源消耗日统计',
              icon: 'icon-diamond',
              guard: ['Admin', 'Super', 'Finance']
            }
          },
          {
            path: 'redPacketDetail',
            name: 'RedPacketDetail',
            component: () => import('@/views/consumptionRecords/RedPacketDetail.vue'),
            meta: {
              title: '红包发放明细',
              icon: 'icon-envelope',
              guard: ['Admin', 'Super', 'Finance', 'Dealers']
            }
          },
        ]
      },
      {
        path: '/finance',
        name: 'finance',
        meta: {
          title: '财务',
          icon: 'icon-currency',
          guard: ['Admin', 'Super', 'Finance']
        },
        children: [
          {
            path: 'cdnPay',
            name: 'CdnPay',
            component: () => import('@/views/finance/CdnPay.vue'),
            meta: {
              title: '视频流量充值日志',
              icon: 'icon-flash-payment',
              guard: ['Admin', 'Super', 'Finance']
            }
          },
          {
            path: 'smsPay',
            name: 'SmsPay',
            component: () => import('@/views/finance/SmsPay.vue'),
            meta: {
              title: '短信充值列表',
              icon: 'icon-wallet',
              guard: ['Admin', 'Super', 'Finance']
            }
          },
          {
            path: 'redPacketPay',
            name: 'RedPacketPay',
            component: () => import('@/views/finance/RedPacketPay.vue'),
            meta: {
              title: '红包预存记录',
              icon: 'icon-pay-code-two',
              guard: ['Admin', 'Super', 'Finance'],
              add: ['Admin', 'Super']
            }
          },
          {
            path: 'payLog',
            name: 'PayLog',
            component: () => import('@/views/finance/PayLog.vue'),
            meta: {
              title: '充值列表',
              icon: 'icon-paypal',
              guard: ['Admin', 'Super', 'Finance']
            }
          }
        ]
      },
      {
        path: '/setting',
        name: 'Setting',
        meta: {
          title: '系统设置',
          icon: 'icon-config',
          guard: ['Admin', 'Super']
        },
        children: [
          {
            path: 'systemParameter',
            name: 'SystemParameter',
            component: () => import('@/views/setting/systemParameter.vue'),
            meta: {
              title: '系统参数',
              icon: 'icon-setting-two',
              guard: ['Admin', 'Super']
            }
          },
          // {
          //   path: 'attachmentSettings',
          //   name: 'AttachmentSettings',
          //   component: () => import('@/views/setting/AttachmentSettings.vue'),
          //   meta: {
          //     title: '附件设置',
          //     icon: 'icon-slave',
          //     guard: ['Admin', 'Super']
          //   }
          // },
          // {
          //   path: 'paymentParameters',
          //   name: 'PaymentParameters',
          //   component: () => import('@/views/setting/PaymentParameters.vue'),
          //   meta: {
          //     title: '支付参数',
          //     icon: 'icon-pay-code-two',
          //     guard: ['Admin', 'Super']
          //   }
          // },
          // {
          //   path: 'wxParameter',
          //   name: 'WxParameter',
          //   component: () => import('@/views/setting/WxParameter.vue'),
          //   meta: {
          //     title: '微信参数',
          //     icon: 'icon-wechat',
          //     guard: ['Admin', 'Super']
          //   }
          // }
        ]
      }
    ]
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.noLogin) {
    // noLogin true，则无登录和权限验证
    if (to.name === 'Login') {
      // 若已登录，进入登录页时直接跳转到首页
      if (store.getters.doneToken) {
        // 重定向到首页
        next('/')
      } else {
        // 若store无token，读取localstorage
        let userInfo = common.getUserInfo()
        if (userInfo.token) {
          // 补充store中的token和role
          store.commit('modifyToken', { token: userInfo.token, expire: userInfo.expire })
          store.commit('modifyRole', userInfo.role)
          // 重定向到首页
          next('/')
        } else {
          next()
        }
      }
    }
  } else {
    // 是否登录
    if (store.getters.doneToken) {
      // 访问权限逻辑
      if (to.meta.guard && to.meta.guard.indexOf(store.state.role) === -1) {
        next('/')
        return false
      }
      // 继续
      next()
    } else {
      // 若store无token，读取localstorage
      let userInfo = common.getUserInfo()
      // let now = Date.parse(new Date()) / 1000;
      if (userInfo.token) {
        // 补充store中的token和role
        store.commit('modifyToken', { token: userInfo.token, expire: userInfo.expire })
        store.commit('modifyRole', userInfo.role)

        // 访问权限逻辑
        if (to.meta.guard && to.meta.guard.indexOf(store.state.role) === -1) {
          next('/')
          return false
        }
        // 继续
        next()
      } else {
        // 清空登录信息
        store.commit('modifyToken', { token: '', expire: 0 })
        store.commit('modifyRole', '')
        common.setUserInfo('')
        common.setSystenInfo('')
        //获取地址栏参数字符串
        let paramStr = window.location.search;
        let params = {};
        // 通过正则表达式或字符串原型方法解析参数字符串
        if (paramStr) {
          paramStr = paramStr.substring(1);
          const paramArr = paramStr.split('&');
          for (let i = 0; i < paramArr.length; i++) {
            const keyValueArr = paramArr[i].split('=');
            params[keyValueArr[0]] = keyValueArr[1];
          }
        }
        if (params.key) {
          login.getCloud({key:params.key}).then(res => {
              if(res.data.code == 200) {
                let now = Date.parse(new Date()) / 1000;
                let token = {
                  token: res.data.data.access_token,
                  expire: now + 86400 * 1
                }
                store.commit('modifyToken', token)
                let roles = res.data.data.roles
                let wx = res.data.data.wx
                let info = res.data.data.info
                let userInfo = token
                userInfo.role = roles.role_name
                userInfo.id = roles.id
                userInfo.wxId = 0
                userInfo.nickname = info.nick_name
                userInfo.avatar = info.face
                if (userInfo.wx) {
                  userInfo.wx = {
                    id: wx.id,
                    nick_name: wx.nick_name,
                    phone: wx.phone
                  }
                }
                // 记录用户信息
                store.commit('modifyRole', roles.role_name)
                common.setUserInfo(userInfo)
                next()
              }
          })
        }else {
          // 重定向回登录页
          next({ name: 'Login' })
        }
      }
    }
    // 占位（无意义）
    from
  }
})

export default router

import store from "@/store";

/**
 * 读取登录后的用户信息
 */
const getUserInfo = () => {
    let userInfo = window.localStorage.getItem('userInfo');

    try {
        userInfo = JSON.parse(userInfo);
    } catch (err) {
        userInfo = '';
    }

    if (userInfo) {
        let now = Date.parse(new Date()) / 1000;

        if ((userInfo.expire - now) < 600) {
            userInfo = '';
        }
    }

    return userInfo ? userInfo : { 'token': '' };
}

/**
 * 存储登录后的用户信息
 */
const setUserInfo = (userInfo) => {
    window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
}

const BaseSelf = {
	_keyStr: "CDnEFGHI6JKMklRST8UVWoXY/Zabc7defghijmpq=Brstu+vwxAyzL012NPQ3459O",

	encode: function(e) {
		var t = "";
		var n, r, i, s, o, u, a;
		var f = 0;
		e = BaseSelf._utf8_encode(e);
		while (f < e.length) {
			n = e.charCodeAt(f++);
			r = e.charCodeAt(f++);
			i = e.charCodeAt(f++);
			s = n >> 2;
			o = (n & 3) << 4 | r >> 4;
			u = (r & 15) << 2 | i >> 6;
			a = i & 63;
			if (isNaN(r)) {
				u = a = 64
			} else if (isNaN(i)) {
				a = 64
			}
			t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr
				.charAt(a)
		}

		t = t.replaceAll('a', '!')
		t = t.replaceAll('b', '@')
		t = t.replaceAll('c', '#')
		t = t.replaceAll('1', '$')
		t = t.replaceAll('2', '%')
		t = t.replaceAll('3', '-')

		t = t.replaceAll('!', 'a1')
		t = t.replaceAll('@', 'a2')
		t = t.replaceAll('#', 'a3')
		t = t.replaceAll('$', 'b1')
		t = t.replaceAll('%', 'b2')
		t = t.replaceAll('-', 'b3')
		t = t.replaceAll('=', 'c1')
		t = t.replaceAll('+', 'c2')
		t = t.replaceAll('/', 'c3')

		return t
	},
	decode: function(e) {
		e = e.replaceAll('a1', '!')
		e = e.replaceAll('a2', '@')
		e = e.replaceAll('a3', '#')
		e = e.replaceAll('b1', '$')
		e = e.replaceAll('b2', '%')
		e = e.replaceAll('b3', '-')
		e = e.replaceAll('c1', '=')
		e = e.replaceAll('c2', '+')
		e = e.replaceAll('c3', '/')

		e = e.replaceAll('!', 'a')
		e = e.replaceAll('@', 'b')
		e = e.replaceAll('#', 'c')
		e = e.replaceAll('$', '1')
		e = e.replaceAll('%', '2')
		e = e.replaceAll('-', '3')

		var t = "";
		var n, r, i;
		var s, o, u, a;
		var f = 0;
		e = e.replace(/[^A-Za-z0-9+/=]/g, "");
		while (f < e.length) {
			s = this._keyStr.indexOf(e.charAt(f++));
			o = this._keyStr.indexOf(e.charAt(f++));
			u = this._keyStr.indexOf(e.charAt(f++));
			a = this._keyStr.indexOf(e.charAt(f++));
			n = s << 2 | o >> 4;
			r = (o & 15) << 4 | u >> 2;
			i = (u & 3) << 6 | a;
			t = t + String.fromCharCode(n);
			if (u != 64) {
				t = t + String.fromCharCode(r)
			}
			if (a != 64) {
				t = t + String.fromCharCode(i)
			}
		}
		t = BaseSelf._utf8_decode(t);
		return t
	},
	_utf8_encode: function(e) {
		e = e.replace(/rn/g, "n");
		var t = "";
		for (var n = 0; n < e.length; n++) {
			var r = e.charCodeAt(n);
			if (r < 128) {
				t += String.fromCharCode(r)
			} else if (r > 127 && r < 2048) {
				t += String.fromCharCode(r >> 6 | 192);
				t += String.fromCharCode(r & 63 | 128)
			} else {
				t += String.fromCharCode(r >> 12 | 224);
				t += String.fromCharCode(r >> 6 & 63 | 128);
				t += String.fromCharCode(r & 63 | 128)
			}
		}
		return t
	},
	_utf8_decode: function(e) {
		var t = "";
		var n = 0;
		var r, c2, c3;
		r  = c2 = c3 = 0
		while (n < e.length) {
			r = e.charCodeAt(n);
			if (r < 128) {
				t += String.fromCharCode(r);
				n++
			} else if (r > 191 && r < 224) {
				c2 = e.charCodeAt(n + 1);
				t += String.fromCharCode((r & 31) << 6 | c2 & 63);
				n += 2
			} else {
				c2 = e.charCodeAt(n + 1);
				c3 = e.charCodeAt(n + 2);
				t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
				n += 3
			}
		}
		return t
	}
}
/**
 * 读取登录后的系统参数
 */
const getSystenInfo = () => {
    let systemInfo = window.localStorage.getItem('systemInfo')

    try {
        systemInfo = JSON.parse(systemInfo)
    } catch (err) {
        systemInfo = {}
    }

    return systemInfo
}

/**
 * 存储登录后的系统参数
 */
const setSystenInfo = (systemInfo) => {
    window.localStorage.setItem('systemInfo', JSON.stringify(systemInfo))
}

/**
 * 按钮权限
 * 
 * @param object route    调用页this.$route
 * @param string pageName 调用页名称
 * @param string btnType  按钮类型
 * 
 * @returns boolean
 */
const btnAuth = (route, pageName, btnType) => {
    if (route.name === pageName) {
        let meta = route.meta[btnType]

        return meta.indexOf(store.state.role) === -1 ? false : true
    } else {
        return false
    }
}

/**
 * 访问权限
 * 
 * @param array guard meta中的guard
 * 
 * @returns boolean
 */
const routeAuth = (guard) => {
    return guard.indexOf(store.state.role) === -1 ? false : true
}

/**
 * 是否为空（empty: '', '0', 0, false, null, NaN, undefined, {}, []）
 * 
 * @param mix param 需要判断是否为空的参数
 * 
 * @return boolean true:空，false:非空
 */
const isEmpty = (param) => {
    let res = false

    if (param) {
        let type = typeof param

        if (type === 'string') {
            if (param === '0') {
                res = true
            }
        }

        if (type === 'object') {
            let paramToStr = JSON.stringify(param)

            if (paramToStr === '{}' || paramToStr === '[]') {
                res = true
            }
        }
    } else {
        res = true
    }

    return res
}

/**
 * 时间戳（秒级）转字符串日期
 */
const timeToDate = (time) => {
    let date = new Date(time * 1000)
    let y = date.getFullYear(),
        m = date.getMonth() + 1,
        d = date.getDate(),
        h = date.getHours(),
        i = date.getMinutes(),
        s = date.getSeconds()
    if (m < 10) {
        m = '0' + m
    }
    if (d < 10) {
        d = '0' + d
    }
    if (h < 10) {
        h = '0' + h
    }
    if (i < 10) {
        i = '0' + i
    }
    if (s < 10) {
        s = '0' + s
    }
    let t = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s

    return t
}

/**
 * 时间（new Date()）转字符串日期（年月日）
 */
const newDateToDay = (newDate) => {
    let y = newDate.getFullYear(),
        m = newDate.getMonth() + 1,
        d = newDate.getDate()
    if (m < 10) {
        m = '0' + m
    }
    if (d < 10) {
        d = '0' + d
    }
    let t = y + '-' + m + '-' + d

    return t
}

/**
 * 时间（new Date()）转字符串日期（年月日时分秒）
 */
const newDateToDateTime = (newDate) => {
    let y = newDate.getFullYear(),
        m = newDate.getMonth() + 1,
        d = newDate.getDate(),
        h = newDate.getHours(),
        i = newDate.getMinutes(),
        s = newDate.getSeconds()
    if (m < 10) {
        m = '0' + m
    }
    if (d < 10) {
        d = '0' + d
    }
    if (h < 10) {
        h = '0' + h
    }
    if (i < 10) {
        i = '0' + i
    }
    if (s < 10) {
        s = '0' + s
    }
    let t = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s

    return t
}

// 输出
export default {
    getUserInfo,
    setUserInfo,
    getSystenInfo,
    setSystenInfo,
    btnAuth,
    routeAuth,
    isEmpty,
    timeToDate,
    newDateToDay,
    newDateToDateTime,
    BaseSelf
}
import axios from '@/common/axios'
import store from '@/store'
import conf from '@/common/conf.js'
import md5 from 'js-md5'

// 登录
const login = (form) => {
    // 整理格式
    let data = {}
    data.username = form.username.trim()
    data.password =md5(form.password) 
    data.captcha = form.captcha
    data['V1-Admin-Captcha-Id'] = form['V1-Admin-Captcha-Id']
    // data.src = form.captchaApi
    // data.shop = ''

    return axios({
        url: conf.apiUrl + '/login',
        method: 'post',
        data
    })
}

// 验证码
const getCaptcha = (params) => {
    return axios({
        url: conf.apiUrl + '/captcha',
        method: 'get',
        // responseType: 'arraybuffer',
        params
    })
}

// 获取用户信息
const getUserInfo = () => {
    return axios({
        url: conf.apiUrl + '/member/info',
        method: 'get',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` }
    })
}

// 获取用户前端信息
const getUserWechat = (data) => {
    return axios({
        url: conf.apiUrl + '/weixin.Fans/fansId',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data
    })
}

// 获取系统参数
const getSystemInfo = () => {
    return axios({
        url: conf.apiUrl + '/files/indexset',
        method: 'get',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` }
    })
}

// 云控跳转 登录接口
const getCloud = (data) => {
    return axios({
        url: conf.apiUrl + '/adminLogin',
        method: 'post',
        headers: { Authorization: `Bearer ${store.getters.doneToken}` },
        data
    })
}


// 输出
export default {
    login,
    getCaptcha,
    getUserInfo,
    getUserWechat,
    getSystemInfo,
    getCloud
}

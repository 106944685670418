import conf from "@/common/conf.js";
import common from "@/common/common.js"
import language from "@/common/language.js"

export default{
    install: (app) => {
        app.config.globalProperties['$conf'] = conf ;
        app.config.globalProperties['$lang'] = language ;
        app.config.globalProperties['$getSystenInfo'] = common.getSystenInfo ;
        app.config.globalProperties['$btnAuth'] = common.btnAuth ;
        app.config.globalProperties['$isEmpty'] = common.isEmpty ;
        app.config.globalProperties['$timeToDate'] = common.timeToDate ;
    }
}

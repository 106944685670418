<template>
  <el-dialog
    v-model="visible"
    :title="title"
    width="500px"
    @close="close()"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="原密码" prop="source">
        <el-input v-model.trim="form.source" type="password" />
      </el-form-item>
      <el-form-item label="新密码" prop="password">
        <el-input v-model.trim="form.password " type="password" />
      </el-form-item>
      <el-form-item label="确认密码" prop="again_password">
        <el-input v-model.trim="form.again_password" type="password" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
  
</template>

<script>
import account from '@/api/account.js'

export default {
  name: 'AdminPassword',
  emits: ['fetch-data'],
  data() {
    return {
      title: '密码修改',
      visible: false,
      form: {
        password: '',
        re_password: '',
        confirm_psd: '',
      },
      rules: {
        source: [{ required: true, trigger: 'blur', message: '请输入原密码' }],
        password: [{ required: true, trigger: 'blur', message: '请输入新密码' }],
        again_password: [{ required: true, trigger: 'blur', message: '请输入确认密码' }]
      }
    }
  },
  methods: {
    // 显示
    show() {
      this.visible = true
    },
    // 关闭
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.visible = false
    },
    // 提交
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const res = await account.doAdminPassword(this.form)

          if (res.data.code === 200) {
            this.$notify({
              message: this.title + '成功！',
              duration: 2000,
              type: 'success'
            })
            this.close()
          } else {
            this.$notify({
              message: this.title + '失败！',
              duration: 2000,
              type: 'error'
            })
          }
        }
      })
    }

  }
}
</script>

<style scoped lang="scss">

</style>
